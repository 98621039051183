<template>
    <v-layout justify-center>
        <v-responsive max-width="1280" width="100%">
            <list-heading title="띠배너 관리">
                <template #add-button>
                    <band-banner-form @input="updateItem">
                        <template #activator="{ attrs, on }">
                            <v-btn small fab tile color="transparent primary--text" class="my-n2" v-bind="attrs" v-on="on"> <v-icon>mdi-plus</v-icon> </v-btn>
                        </template>
                    </band-banner-form>
                </template>
            </list-heading>

            <div class="caption primary--text mb-4">* PC 배너 사이즈 1920*300(px) / 모바일 배너 사이즈 720*300(px)</div>

            <v-data-table v-bind="{ loading, headers, items }" disable-sort disable-pagination hide-default-footer class="v-sheet--outlined" :items-per-page="-1">
                <template v-for="(header, index) in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value, item }"> <span :key="index" v-html="header.formatter.bind(item)(value)" /> </template>

                <template #[`item.href`]="{ value }">
                    <v-row align="center" no-gutters>
                        <v-col class="text-truncate"> {{ value || "-" }} </v-col>
                        <v-col cols="auto">
                            <v-btn small text icon :href="value" target="_blank" :disabled="!value"> <v-icon> mdi-link </v-icon></v-btn>
                        </v-col>
                    </v-row>
                </template>
                <template #[`item.imagePc`]="{ value }">
                    <image-popup :src="value?.url" v-bind="{ attrs_avatar }" />
                </template>
                <template #[`item.imageMb`]="{ value }">
                    <image-popup :src="value?.url" v-bind="{ attrs_avatar }" />
                </template>
                <template #[`item.display.enabled`]="{ item }">
                    <v-switch v-model="item.display.enabled" v-bind="{ ...attrs_switch, loading }" @change="(enabled) => update({ _id: item._id, display: { ...item.display, enabled } })" />
                </template>
                <template #[`item.display.hasPeriod`]="{ item }">
                    <v-switch v-model="item.display.hasPeriod" v-bind="{ ...attrs_switch, loading }" @change="(hasPeriod) => update({ _id: item._id, display: { ...item.display, hasPeriod } })" />
                </template>
                <template #[`item.actions`]="{ item }">
                    <band-banner-form :value="item" @input="updateItem">
                        <template #activator="{ attrs, on }">
                            <v-btn small text icon v-bind="attrs" v-on="on"> <v-icon small>mdi-pencil</v-icon> </v-btn>
                        </template>
                    </band-banner-form>
                    <v-btn small text icon @click="remove(item)"> <v-icon small>mdi-delete</v-icon> </v-btn>
                </template>
            </v-data-table>
            <v-pagination :value="page" :length="pageCount" :total-visible="11" color="primary" class="mt-2" @input="(page) => $router.push({ query: { ...$route.query, page } })" />
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import { attrs_switch } from "@/assets/variables";

import ImagePopup from "@/components/console/dumb/image-popup.vue";
import ListHeading from "@/components/console/dumb/list-heading.vue";
import BandBannerForm from "@/components/console/band-banners/band-banner-form.vue";

const headers = [
    { width: 160, text: "명칭", value: "name" },
    { width: 160, text: "코드", value: "code" },
    { width: 290, text: "링크", value: "href", cellClass: "max-width-0" },
    { width: +48, text: "PC", value: "imagePc", align: "center", class: "px-0", cellClass: "px-0" },
    { width: +48, text: "Mobile", value: "imageMb", align: "center", class: "px-0", cellClass: "px-0" },
    { width: +80, text: "노출", value: "display.enabled", align: "center" },
    { width: +80, text: "기간", value: "display.hasPeriod", align: "center" },
    { width: 160, text: "노출시작", value: "display.startedAt", formatter: (value) => value?.toDateTime?.() ?? value ?? "-" },
    { width: 160, text: "노출종료", value: "display.endedAt", formatter: (value) => value?.toDateTime?.() ?? value ?? "-" },
    { width: +80, text: "", value: "actions", align: "center", cellClass: "px-0" },
].map((item, index, array) => ({ ...item, formatter: item.formatter ?? ((value) => value ?? "-"), cellClass: (item.cellClass || "") + " caption", divider: index != array.length - 1 }));

const attrs_avatar = {
    tile: true,
    size: "48",
};

export default {
    components: {
        ImagePopup,
        ListHeading,
        BandBannerForm,
    },
    data: () => ({
        bandBanners: [],
        summary: { totalCount: 0 },

        limit: 10,

        headers,
        attrs_avatar,
        attrs_switch,

        loading: false,
    }),
    computed: {
        items() {
            return [...this.bandBanners];
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        params() {
            let { ...query } = this.$route.query;
            return { ...query };
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        params() {
            this.search();
        },
    },
    methods: {
        async init() {
            this.search();
        },

        async search() {
            if (this.loading) return;
            else this.loading = true;

            try {
                let { skip, limit, params } = this;
                var { summary, bandBanners } = await api.console.bandBanners.gets({
                    headers: { skip, limit },
                    params,
                });

                this.summary = summary;
                this.bandBanners = bandBanners;
            } finally {
                this.loading = false;
            }
        },

        async update(item) {
            if (this.loading) return;
            else this.loading = true;

            try {
                item = (await api.console.bandBanners.put(item))?.bandBanner;
                this.updateItem(item);
            } finally {
                this.loading = false;
                this.search();
            }
        },

        async remove(item) {
            const go = confirm(`"${item?.name}" 항목을 삭제하시겠습니까?`);
            if (!go) return;

            try {
                await api.console.bandBanners.delete(item);
                this.bandBanners = this.bandBanners.filter(({ _id }) => _id !== item._id);
                this.summary.totalCount -= 1;
                alert("삭제되었습니다.");
            } finally {
            }
        },

        updateItem(item) {
            const index = this.bandBanners.findIndex(({ _id }) => _id == item._id);
            if (0 <= index) this.bandBanners.splice(index, 1, item);
            else {
                this.bandBanners = [item, ...this.bandBanners];
                this.summary.totalCount += 1;
            }
            this.search();
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .max-width-0 {
        max-width: 0;
    }
    .white-space-pre-line {
        white-space: pre-line;
    }
    .v-pagination button {
        box-shadow: none !important;
        border: thin solid rgba(0, 0, 0, 0.12);
    }
}
</style>
